<template>
<div class="footernav">
  <v-footer
    id="default-footer"
    color="transparent"
    absolute
    app
    inset
  >
    <links />
  </v-footer>
</div>
</template>

<script>
  // Components

  export default {
    name: 'DefaultFooter',
  }
</script>
<style scoped>


</style>
